import { Roles } from "../constant/Roles";
import http from "./httpService";

export const ReportService = {

  DownloadKpiReport,
  DownloadCertificateReport,
  DownloadDetailedReport,
  DownloadVolunteerReport,
  DownloadProjectReport
};

    function DownloadKpiReport(id){
      return http.get(`/api/Report/DownloadKpiReport?projectId=${id}`,{ responseType: 'blob' });
    }

    function DownloadCertificateReport(){
      return http.get(`/api/Report/DownloadCertificateReport`,{ responseType: 'blob' });
    }

    function DownloadDetailedReport(id,from,to){
      var q = '';
      if (id) q += `projectId=${id}`
      if (to) q += `to=${to}`
      if (from) q += `from=${from}`
      return http.get(`/api/Report/DownloadDetailedReport?${q}`,{ responseType: 'blob' });
    }

    function DownloadVolunteerReport(data){
      return http.post(`/api/Report/DownloadVolunteerReport`,data,{ responseType: 'blob' });
    }

    function DownloadProjectReport(projectStatus){
      return http.get(`/api/Report/DownloadProjectReport?projectStatus=${projectStatus}`,{ responseType: 'blob' });
    }
  
  
  
    



