import React, { useContext, useState } from "react";
import { LayoutContext } from "../../Contexts/LayoutContext";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate  } from "react-router-dom";
import { AuthService } from "../../shared/Services/AuthService";
import AppConsts from "../../shared/constant/appConsts";

const Header = () => {
  localStorage.getItem("lang") == null && changeLanguage("ar");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage =localStorage.getItem("lang") === "ar" ? "ar" : "en";
  function changeLanguage(value) {
    localStorage.setItem("lang", value);
    window.location.reload();
  }
  return (
    <>
         <nav className="navbar navbar-expand-lg navbar-light menu-shadow main-navbar ">
    <div className="container">
     
      <a className="navbar-brand" href={AppConsts.indexUrl}><img  src={require("../../assests/Images/khccLogo.png")} className="main-img"/></a>
      <span className="navbar-brand" >          <img
                  src={require("../../assests/Images/footer-logo.png")}
                  className="footer-logo"
                  alt="footer"
                /></span>

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon">   
    <i className="fas fa-bars" style={{color:"#fff", fontSize:"26px"}}></i>
</span>
      </button>
  
      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul className="navbar-nav">
          {AuthService.isLoggedIn() && (<>
            <li className="nav-item mx-2 mb-1">
          <Button
                  label={t("Dashboard")}
                  className="btn btn-primary  w-100"
                  type="button"
                  name="back"
                  onClick={(e) => {
                    navigate("/Dashboard");
                  }}
                />
          </li>
          <li className="nav-item mx-2 mb-1">
          <Button
                  label={t("Log  out")}
                  className="btn btn-info  w-100"
                  type="button"
                  name="back"
                  onClick={(e) => {
                    AuthService.logOut();
                    window.location.href = AppConsts.indexUrl ;

                  }}
                />
          </li>
         
          </>)}
          {!AuthService.isLoggedIn() && (<>
            <li className="nav-item mx-2 mb-1">
          <Button
                  label={t("Log  in")}
                  className="btn btn-info  w-100"
                  type="button"
                  name="back"
                  onClick={(e) => {
                    navigate("/Login");
                  }}
                />
          </li>
          <li className="nav-item mb-1">
          <Button
                  label={t("Sign up")}
                  className="btn btn-primary  w-100"
                  type="button"
                  name="back"
                  onClick={(e) => {
                    navigate("/Register");

                  }}
                />          </li>
          </>)}
          {/* <li className="nav-item mb-1">
          <Button
                  label={currentLanguage === "ar" ? "English" : "عربي"}

                  className="btn btn-primary  w-100 mx-2"
                  type="button"
                  name="back"
                  onClick={(e) => {
                    changeLanguage(currentLanguage === "ar" ? "en" : "ar");

                  }}
                />         
                 </li> */}
    
        </ul>
      </div>
    </div>
  </nav>
    </>
  );
};
export default Header;

