import { Roles } from "../constant/Roles";
import http from "./httpService";

export const HomeService = {
  getEvents,
  getNationalities,
  getCities,
  getBloodType,
  getSpecialSkills,
  getSlider,
  getDepartmentLookups,
  getMajors,
  getEducationStatuses,
  GetAreas,
  GetAreasByCity,
  GetCountries,
  GetFieldOfStudies,
  GetRequesterDashboard,
  GetVolunteerDashboard,
  GetAdminDashboard,
  getShiftsLookups,
  GetCertificate,
  GetNewsItem,
  GetWhatsNews,
  GetCitiesByCountry
};



function getEvents(){
  return http.post("/api/Home/GetEvents",{
    pageSize:20,
    pageNumber: 1
  } );
}

function getNationalities(){
  return http.get("/api/Home/GetNationalities" );
}
function getCities(){
  return http.get("/api/Home/GetCities" );
}

function GetCitiesByCountry(countryId){
  return http.get(`/api/Home/GetCitiesByCountry?countryId=${countryId}` );
}

function getBloodType(){
  return http.get("/api/Home/GetBloodType" );
}

function getSpecialSkills(){
  return http.get("/api/Home/GetSpecialSkills" );
}

function getSlider(){
  return http.get("/api/Home/GetSlider" );
}

function getDepartmentLookups(){
  return http.get("/api/Home/GetDepartmentLookups" );
}

function getEducationStatuses(){
  return http.get("/api/Home/GetEducationStatuses" );
}

function getMajors(){
  return http.get("/api/Home/GetMajors" );
}


function GetCertificate(){
  return http.get("/api/Home/GetCertificate", { responseType: 'arraybuffer' } );
}

function GetAreasByCity(cityId){
  return http.get(`/api/Home/GetAreas?cityId=${cityId}` );
}
function GetAreas(){
  return http.get("/api/Home/GetAreas" );
}

function GetCountries(){
  return http.get("/api/Home/GetCountries" );
}

function GetFieldOfStudies(){
  return http.get("/api/Home/GetFieldOfStudies" );
}

function GetAdminDashboard(){
  return http.get("/api/Home/GetAdminDashboard" );
}

function GetVolunteerDashboard(){
  return http.get("/api/Home/GetVolunteerDashboard" );
}

function GetRequesterDashboard(){
  return http.get("/api/Home/GetRequesterDashboard" );
}

function getShiftsLookups(){
  return http.get("/api/Home/GetShiftTypes" );
}

function GetNewsItem(){
  return http.get("/api/Home/GetNewsItem" );
}

function GetWhatsNews(){
  return http.get("/api/Home/GetWhatsNews" );
}


