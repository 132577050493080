import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
import AppConsts from "../../shared/constant/appConsts";
import * as yup from "yup";
import { ReportService } from "../../shared/Services/ReportService";
import moment from 'moment';

const ActivitiesReports = ({}) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);


  function  ExportData(projectId,from,to) {
    SharedService.ShowLoader();
    ReportService.DownloadDetailedReport(projectId,from,to).then((response) => {
      SharedService.HideLoader();
      if (response && response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const formattedDate = moment().format('YYYY-MM-DD HH:mm');
        const filename = `Activities Reports ${formattedDate}`;
        link.href = url;
        link.setAttribute(
            'download',
            `${filename}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        
        link.remove();
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      projectId: "",
      from:"",
      to:""
    },
    validationSchema: yup.object().shape({
      // projectId: yup.string().required(t("Required Field")),
    }),
    enableReinitialize: true,
    onSubmit: (data) => {
      ExportData(data.projectId, data.from,data.to);
    },
  });

  useEffect(() => {
    fillProjects();
    // eslint-disable-next-line
  }, []);

  function fillProjects() {
    ProjectService.GetProjectsDropdownOptions().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return { name: x.name, code: x.id };
        });
        setProjects(options);
      }
    });
  }

  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row">
          <div className="col-12  title">{t("Activities Reports")}</div>
        </div>

        <div className="row mt-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="row">
              <div className="col-6 col-md-3 ">
                <label htmlFor="project" className="d-inline-block mt-2  w-100">
                  {t("Select Project")}
                </label>
                <Dropdown
                  optionLabel="name"
                  id="projectId"
                  options={projects}
                  showClear
                  value={
                    projects[
                      projects.findIndex(
                        (obj) => +obj.code === formik.values.projectId
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("projectId", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.projectId && formik.errors.projectId && (
                  <label className="errorMessage w-100">
                    {formik.errors.projectId}
                  </label>
                )}
              </div>

                        <div className="col-6 col-md-3 text-center">
                              <span className="text-start">
                                <label
                                  htmlFor="from"
                                  className="d-inline-block mt-2  w-100"
                                >
                                  {t("From")}
                                </label>
                                <Calendar
                                  showIcon
                                  id="from"
                                  className={`block w-100`}
                                  name="from"
                                  value={
                                    formik.values.from
                                      ? new Date(formik.values.from)
                                      : ""
                                  }
                                  maxDate={new Date()}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "from",
                                      moment(new Date(e.value)).format("YYYY-MM-DD")
                                    );
                                  }}
                                />
                                {formik.touched.from && formik.errors.from && (
                                  <label className="errorMessage w-100">
                                    {formik.errors.from}
                                  </label>
                                )}
                              </span>
                            </div>


                                      <div className="col-6 col-md-3 text-center">
                                            <span className="text-start">
                                              <label
                                                htmlFor="to"
                                                className="d-inline-block mt-2  w-100"
                                              >
                                                {t("To")}
                                              </label>
                                              <Calendar
                                                showIcon
                                                id="to"
                                                className={`block w-100`}
                                                name="to"
                                                value={
                                                  formik.values.to
                                                    ? new Date(formik.values.to)
                                                    : ""
                                                }
                                                maxDate={new Date()}
                                                onChange={(e) => {
                                                  formik.setFieldValue(
                                                    "to",
                                                    moment(new Date(e.value)).format("YYYY-MM-DD")
                                                  );
                                                }}
                                              />
                                              {formik.touched.to && formik.errors.to && (
                                                <label className="errorMessage w-100">
                                                  {formik.errors.to}
                                                </label>
                                              )}
                                            </span>
                                          </div>

              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="department"
                  className="d-inline-block mt-2  w-100"
                ></label>
                <Button
                  label={t("Export")}
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ActivitiesReports;
