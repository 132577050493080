import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import Accordion from "react-bootstrap/Accordion";
import { Field } from "formik";
import moment from "moment";
import { InputNumber } from 'primereact/inputnumber';
import { WizardDataContext } from "../../../Contexts/WizardDataContext";
import { json } from "react-router-dom";
import { FormMode } from "../../../shared/constant/FormMode";
import { SharedService } from "../../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../../shared/constant/messageTypeConstant";
import { MultiSelect } from 'primereact/multiselect';
import { ProjectService } from "../../../shared/Services/ProjectService";
import { useNavigate,useOutletContext  } from "react-router-dom";
import {Dialog} from "primereact/dialog";
import ViewVolunteer from "../../Volunteers/ViewVolunteer";

const Step2 = () => {
  const { t } = useTranslation();
  const [volunteers, setVolunteers] = useState([]);
  const navigate = useNavigate();
  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const [vId, setVId] = useState(null);

  const { wizardData, setWizardData, setActiveIndex, setStep2,step2,step1 } =
    useContext(WizardDataContext);

  useEffect(() => {
    fillVolunteers("");
  }, []);

  function fillVolunteers(text){
    SharedService.ShowLoader();

    ProjectService.getApprovedProjectVolunteer(text,wizardData.id).then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return { name: x.value , id: x.id };
        });
        setVolunteers(options);
      }
    });
  
}

  const validationSchema = yup.object().shape({
    ProjectTasks: yup.array().min(1, t("must be at least 1 task")).required("Required Field").of(yup.object().shape({
      from: yup.string().nullable(true).required(t("Required Field")),
      to: yup.string().nullable(true).required(t("Required Field")),
      // nameEn: yup.string().required(t("Required Field")),
      nameAr: yup.string().required(t("Required Field")),
      // descriptionEn: yup.string().required(t("Required Field")),
      descriptionAr: yup.string().required(t("Required Field")),
      kpiUnit: yup.string().required(t("Required Field")),
      kpiNumber: yup.string().nullable(true).required(t("Required Field")),
      numberOfVolunteersNeeded: yup.string().nullable(true).required(t("Required Field")),


    })),


  });
  const assignVolunteersTemplate = (option) => {
    return (
      <div className="d-flex justify-content-between volunteersTemplate">
      <div>{option.name}</div>
      <i role="button" className="fa fa-eye mx-5" aria-hidden="true"  onClick={(e) => {
            e.preventDefault(); 
            e.stopPropagation();
          setVId({
            volunteerId:option.id
          });
        }}></i>
    </div>
    );
}

  const selectedVolunteersTemplate = (option) => {
    if (option) {
        return (
              <span>  {option.name},</span>
           
        );
    }

    return t("No volunteers assigned");
}

  return (
    <>
           <Dialog
      header={vId?.volunteerName}
      visible={vId !==null}
      style={{minWidth:"30vw", maxHeight:"95vh"}}
      onHide={
        ()=>{
          setVId(null);
        }
      }
>
  {(vId)&& <ViewVolunteer id={vId?.volunteerId} />}

    </Dialog>
      <div className="container">
        <Formik
          initialValues={
            step2
              ? step2
              : {
                  ProjectTasks: [],
                }
          }
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let count = 0 ;
            values.ProjectTasks.forEach((element) => {
            count = count + element.numberOfVolunteersNeeded
            });
            if (count !== +step1.NumberOfVolunteersNeeded){
              SharedService.ShowToast(t("Count Volunteers must equal ") + step1.NumberOfVolunteersNeeded,MessageTypeConstant.Error )
              return;
            }
            debugger;
            if (wizardData.mode === FormMode.ASSIGN) {
              let data = [];
              let error = false;
              values.ProjectTasks.forEach((element) => {
                if (!element.assignedVolunteers || element.assignedVolunteers.length === 0) {
                  SharedService.ShowToast( t("each task must have at least one volunteer"),
                  MessageTypeConstant.Error
                  );
                  error = true ;
                }
                data.push({
                  taskId: element.id,
                  volunteerIds: element.assignedVolunteers.map(
                    (item) => item.id
                  ),
                });
              });

              if(error)
              return;
              SharedService.ShowLoader();

              ProjectService.assignProjectTasks(data).then((res) => {
                SharedService.HideLoader();

                if (res.data.errorCode === 0) {
                  SharedService.ShowToast(
                    t("Project Approved Successfully"),
                    MessageTypeConstant.Success
                  );
                  navigate("/Projects/List");
                }
              });
            } else {
              setStep2(values);
              setActiveIndex(2);
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
          }) => (
            <Form>
              <FieldArray name="ProjectTasks" className="mb-5">
                {({ push, remove }) => (
                  <>
                    <div key="ProjectTasks">
                      <Accordion className="wizard-accordion  mt-4 mb-4">
                        {values?.ProjectTasks?.map((p, index) => {
                          return (
                            <React.Fragment key={"task" + index}>
                              <Accordion.Item
                                eventKey={"task" + index}
                                className="mt-3"
                              >
                                <Accordion.Header>
                                  <React.Fragment>
                                    <div className="row justify-content-between w-100">
                                      <div className={"col-3 p-2 navy-blue"}>
                                        {t("Task")} {index + 1}
                                      </div>
                                      {(wizardData.mode === FormMode.NEW ||
                                        wizardData.mode === FormMode.EDIT) && (
                                        <div
                                          className={
                                            "col-3 p-2 fa fa-trash text-danger text-end mx-2"
                                          }
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="mb-3 row">
                                    <Field name={`ProjectTasks[${index}].from`}>
                                      {({ field, form, meta }) => (
                                        <div className="col-12 col-md-6 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("From")}
                                            </label>
                                            <Calendar
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              minDate={new Date(step1.From)}
                                              maxDate={ new Date(step1.To)}
                                              showIcon
                                              className={`block w-100`}
                                              name="From"
                                              {...field}
                                              value={
                                                field.value
                                                  ? new Date(field.value)
                                                  : ""
                                              }
                                              onSelect={(e) => {
                                                if (e.value) {
                                                  setFieldValue(
                                                    `ProjectTasks[${index}].from`,
                                                    moment(
                                                      new Date(e.value)
                                                    ).format("YYYY-MM-DD")
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    `ProjectTasks[${index}].from`,
                                                    null
                                                  );
                                                }
                                                setFieldValue(
                                                  `ProjectTasks[${index}].to`,
                                                 null
                                                );
                                              }}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field>
                                    <Field name={`ProjectTasks[${index}].to`}>
                                      {({ field, form, meta }) => (
                                        <div className="col-12 col-md-6 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("To")}
                                            </label>
                                            <Calendar
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                ) || !p.from
                                              }
                                              minDate={
                                                p.from ? new Date(p.from) : null
                                              }
                                              maxDate={
                                                new Date(step1.To)
                                              }
                                              showIcon
                                              className={`block w-100`}
                                              name="to"
                                              {...field}
                                              value={
                                                field.value
                                                  ? new Date(field.value)
                                                  : ""
                                              }
                                              onSelect={(e) => {
                                                setFieldValue(
                                                  `ProjectTasks[${index}].to`,
                                                  moment(
                                                    new Date(e.value)
                                                  ).format("YYYY-MM-DD")
                                                );
                                              }}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field>
                                    {/* <Field
                                      name={`ProjectTasks[${index}].nameEn`}
                                    >
                                      {({ field, form, meta }) => (
                                        <div className="col-12 col-md-6 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("Task Name English")}
                                            </label>
                                            <InputText
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              className={`block w-100`}
                                              name="nameEn"
                                              {...field}
                                              value={field.value}
                                              onChange={field.onChange}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field> */}
                                    <Field
                                      name={`ProjectTasks[${index}].nameAr`}
                                    >
                                      {({ field, form, meta }) => (
                                        <div className="col-12 col-md-6 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("Task Name Arabic")}
                                            </label>
                                            <InputText
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              className={`block w-100`}
                                              name="nameAr"
                                              {...field}
                                              value={field.value}
                                              onChange={field.onChange}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field>

                                    {/* <Field
                                      name={`ProjectTasks[${index}].descriptionEn`}
                                    >
                                      {({ field, form, meta }) => (
                                        <div className="col-12 col-md-6 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("Task Brief")}
                                            </label>
                                            <InputText
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              className={`block w-100`}
                                              name="descriptionEn"
                                              {...field}
                                              value={field.value}
                                              onChange={field.onChange}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field> */}
                                    <Field
                                      name={`ProjectTasks[${index}].descriptionAr`}
                                    >
                                      {({ field, form, meta }) => (
                                        <div className="col-12 col-md-6 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("Task Brief Arabic")}
                                            </label>
                                            <InputText
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              className={`block w-100`}
                                              name="descriptionAr"
                                              {...field}
                                              value={field.value}
                                              onChange={field.onChange}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field>
                                    <Field
                                      name={`ProjectTasks[${index}].numberOfVolunteersNeeded`}
                                    >
                                      {({ field, form, meta }) => (
                                        <div className="col-6 col-md-4 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("Volunteers Needed")}
                                            </label>
                                            <InputNumber
                                              showButtons
                                              min={1}
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              className={`block w-100`}
                                              name="numberOfVolunteersNeeded"
                                              {...field}
                                              value={field.value}
                                              onValueChange={field.onChange}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field>
                                    <Field
                                      name={`ProjectTasks[${index}].kpiUnit`}
                                    >
                                      {({ field, form, meta }) => (
                                        <div className="col-12 col-md-4 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("KPI Unit")}
                                            </label>
                                            <InputText
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              className={`block w-100`}
                                              name="kpiUnit"
                                              {...field}
                                              value={field.value}
                                              onChange={field.onChange}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field>
                                    <Field
                                      name={`ProjectTasks[${index}].kpiNumber`}
                                    >
                                      {({ field, form, meta }) => (
                                        <div className="col-6 col-md-4 mt-1 text-center">
                                          <span className="text-start">
                                            <label
                                              htmlFor={field.name}
                                              className="d-inline-block mt-2  w-100"
                                            >
                                              {t("KPI Number")}
                                            </label>
                                            <InputNumber
                                              showButtons
                                              min={1}
                                              disabled={
                                                !(
                                                  wizardData.mode ===
                                                    FormMode.NEW ||
                                                  wizardData.mode ===
                                                    FormMode.EDIT
                                                )
                                              }
                                              className={`block w-100`}
                                              name="kpiNumber"
                                              {...field}
                                              value={field.value}
                                              onValueChange={field.onChange}
                                            />
                                            {meta.touched && meta.error && (
                                              <label className="errorMessage w-100">
                                                {meta.error}
                                              </label>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Field>
                                    {wizardData.mode === FormMode.ASSIGN && (
                                      <Field
                                        name={`ProjectTasks[${index}].assignedVolunteers`}
                                      >
                                        {({ field, form, meta }) => (
                                          <>
                                            <div className="col-12 mt-1 text-center">
                                              <span className="text-start">
                                                <label
                                                  htmlFor={field.name}
                                                  className="d-inline-block mt-2  w-100"
                                                >
                                                  {t("Assigned Volunteers")}
                                                </label>
                                                <MultiSelect
                                                  value={field.value}
                                                  options={volunteers}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `ProjectTasks[${index}].assignedVolunteers`,
                                                      e.value
                                                    );
                                                  }}
                                                  selectedItemTemplate={
                                                    selectedVolunteersTemplate
                                                  }
                                                  className={`w-100`}
                                                  filter
                                                  optionLabel="name"
                                                  onFilter={(e) => {
                                                    fillVolunteers(e.filter);
                                                  }}
                                                  itemTemplate={assignVolunteersTemplate}
                                                />

                                                {meta.touched && meta.error && (
                                                  <label className="errorMessage w-100">
                                                    {meta.error}
                                                  </label>
                                                )}
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </Field>
                                    )}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </React.Fragment>
                          );
                        })}
                      </Accordion>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        {(wizardData.mode === FormMode.NEW ||
                          wizardData.mode === FormMode.EDIT) && (
                          <Button
                            label={t("Add Task")}
                            className="btn btn-blue px-5"
                            type="button"
                            onClick={() => {
                              push({
                                from: "",
                                to: "",
                                nameEn: "",
                                nameAr: "",
                                descriptionEn: "",
                                descriptionAr: "",
                                kpiUnit: "",
                                kpiNumber: "",
                                numberOfVolunteersNeeded: "",
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </FieldArray>
              <div className="col-12 mt-5 text-center">
                <Button
                  label={wizardData.mode === FormMode.ASSIGN ? t("Assign & Save"): wizardData.mode === FormMode.VIEW ? t("Next") :  t("Save & Continue")}
                  className="btn btn-blue  w-100"
                  type="submit"
                  onClick={() => {
                    if (values.ProjectTasks.length < 1) {
                      SharedService.ShowToast(
                        t("Must be at least 1 Task"),
                        MessageTypeConstant.Error
                      );
                    }
                  }}
                />
              </div>
              {wizardData.mode !== FormMode.ASSIGN && (   <div className="col-12 mt-5 text-center">
                <Button
                  label={t("Back")}
                  className="btn btn-primary  w-100"
                  type="button"
                  onClick={() => {
                    setStep2(values);
                    setActiveIndex(0);
                  }}
                />
              </div>)}
              <div className="col-12 mt-4 text-center">
               <Button
              label={t("Back to All Projects")}
              className="btn p-button-warning w-100"
              type="button"
              onClick={() => {
                navigate("/Projects/List");
              }}
            />
                   </div>
           
              
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Step2;
