import { React, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';

import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import "./Register.css";
import moment from "moment";
import { HomeService } from "../../shared/Services/HomeService";
import { AuthService } from "../../shared/Services/AuthService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
const RegisterPage = ({}) => {
  const { t } = useTranslation();
  const [genders, setGenders] = useState([]);

  const [nationalities, setNationalities] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);
  const [bloodTypes, setBloodTypes] = useState([]);
  const [specialSkills, setSpecialSkills] = useState([]);
  const [educationStatus, SetEducationStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [fieldofStudies, setFieldofStudies] = useState([]);
  const [isOtherSelected, setIsOtherSelected] = useState(false);





  function fillGenders() {
    setGenders([
      { name: t("Male"), code: 1 },
      { name: t("Female"), code: 2 },
    ]);
  }

  const handleDropdownChange = (e) => {
    const selectedValue = e.value.name;
    formik.setFieldValue("FieldOfStudyId", e.value.code);

    // Show text field if "Other" is selected
    if (selectedValue === "Other" || selectedValue === "أخرى" ) {
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
      formik.setFieldValue("OtherFieldOfStudy", ""); // Clear the "OtherField" value if "Other" is not selected

    }
  };

  function fillNationalities() {
    HomeService.getNationalities().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setNationalities(options);
      }
    });
  }
  function fillEducationStatus() {
    HomeService.getEducationStatuses().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        SetEducationStatus(options);
      }
    });
  }
  function fillFieldofStudies() {
    HomeService.GetFieldOfStudies().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setFieldofStudies(options);
      }
    });
  }

  function fillDistricts() {
    HomeService.GetAreas().then((res) => {
      if (res.data.errorCode === 0) {

        let options = res.data.data.reduce((acc, curr) => {
          const group = acc.find(g => g.label === curr.groupName);
          
          const newItem = {
            label: curr.nameAr,
            value: curr.id.toString()
          };
          
          if (group) {
            group.items.push(newItem);
          } else {
            acc.push({
              label: curr.groupName,
              code: curr.groupName,
              items: [newItem]
            });
          }
          
          return acc;
        }, []);
        // let options = res.data.data.map((x) => {
        //   return {
        //     name: AuthService.isArabic() ? x.nameAr : x.nameEn,
        //     code: x.id,
        //   };
        // });
        setDistricts(options);
      }
    });
  }
  function fillCountries() {
    HomeService.GetCountries().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setCountries(options);
      }
    });
  }
  function fillCities() {
    HomeService.getCities().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setCities(options);
      }
    });
  }

  function fillCitiesByCountry(countryId) {
    HomeService.GetCitiesByCountry(countryId).then((res) => {
      if (res.data.errorCode === 0) {

      
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setCities(options);
      }
    });
  }

  function fillAreasByCity(cityId) {
    HomeService.GetAreasByCity(cityId).then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.reduce((acc, curr) => {
          const group = acc.find(g => g.label === curr.groupName);
          
          const newItem = {
            label: curr.nameAr,
            value: curr.id.toString()
          };
          
          if (group) {
            group.items.push(newItem);
          } else {
            acc.push({
              label: curr.groupName,
              code: curr.cityId.toString(),
              items: [newItem]
            });
          }
          
          return acc;
        }, []);
debugger;
        // let options = res.data.data.map((x) => {
        //   return {
        //     name: AuthService.isArabic() ? x.nameAr : x.nameEn,
        //     code: x.id,
        //   };
        // });
        setDistricts(options);
      }
    });
  }


  function fillWorkTypes() {
    setWorkTypes([
      { name: "full  time", code: "1" },
      { name: "female", code: "2" },
    ]);
  }
  function fillBloodTypes() {
    HomeService.getBloodType().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setBloodTypes(options);
      }
    });
  }
  function fillSpecialSkills() {
    HomeService.getSpecialSkills().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setSpecialSkills(options);
      }
    });
  }
  useEffect(() => {
    fillGenders();
    fillNationalities();
    fillCities();
    fillDistricts();
    fillWorkTypes();
    fillSpecialSkills();
    fillBloodTypes();
    fillEducationStatus();
    fillCountries();
    fillFieldofStudies();
    // eslint-disable-next-line
  }, []);

  const validationSchema = yup.object().shape({
    FullName: yup.string().required(t("Full Name is required")),
    UserName: yup.string().required(t("الايميل مطلوب")).email("الرجاء ادخال ايميل صحيح"),
    Password: yup.string().required(t("Password is required")),
    confirmPassword: yup
      .string()
      .required(t("Confirm Password is required"))
      .oneOf([yup.ref("Password"), null], t("Passwords must match")),
    UserGenderEnum: yup.string().required(t("Gender is required")),
    SpecialSkillsId: yup.array()
    .of(yup.string())
    .min(1, "Please select at least one special skill."),
    // OtherSpecialSkill: yup.string().when("SpecialSkillsId", {
    //   is: (skills) => skills?.includes("Other"), // Check if "Other" is selected
    //   then: yup.string().required("Please specify your special skill."),
    //   otherwise: yup.string().notRequired()}),
    OtherFieldOfStudy: yup.string().when("FieldOfStudyId", {
      is: 164, // Value to check
      then: (schema) => schema.required("Please specify your field of study."),
      otherwise: (schema) => schema.notRequired(),
    }),
    DateOfBirth: yup.string().required(t("Date of Birth is required")),
    NationalityId: yup.string().required(t("Nationality is required")),
    IdNumber: yup.string().required(t("ID Number is required")),
    CountryId: yup.string().required(t("Country is required")),
    CityId: yup.string().required(t("City is required")),
    AreaId: yup.string().required(t("Area is required")),
    PhoneNumber: yup.string().required(t("Phone Number is required")).min(10,'mobile must be 10 digits'),
    ConfirmMobileNo: yup
      .string()
      .required(t("Confirm Phone is required"))
      .oneOf([yup.ref("PhoneNumber"), null], t("Phone Numbers must match")),
    PersonnalPhotoFile: yup.string().required(t("Personal Photo is required")),
    IdPhotoFile: yup.string().required(t("ID Photo is required")),
    EducationStatusId: yup.string().required(t("Education Status is required")),
    BloodTypeId: yup.string().required(t("Blood Type is required")),
    Experience: yup.string().required(t("Experience is required")),

    grantPermission: yup
      .boolean()
      .test(
        "grantPermission",
        t("You must grant permission to continue"),
        (value) => value === true
      ),
    accept: yup
      .boolean()
      .test(
        "grantPermission",
        t("You must accept to continue"),
        (value) => value === true
      ),
  });
  const formik = useFormik({
    initialValues: {
      FullName: "",
      UserName: "",
      Password: "",
      confirmPassword: "",
      UserGenderEnum: "",
      DateOfBirth: "",
      NationalityId: "",
      IdNumber: "",
      CountryId: "",
      CityId: "",
      AreaId: "",
      PhoneNumber: "",
      ConfirmMobileNo: "",
      PersonnalPhotoFile: "",
      IdPhotoFile: "",
      EducationStatusId: "",
      BloodTypeId: "",
      Experience: "",
      FieldOfStudyId:"",
      OtherFieldOfStudy:"",
      IsCurrentyWorking: false,
      IsCurrentlyStudent: false,
      IsWillGovernarates: false,
      IsWillWeekends: false,
      IsCancerSurvivor:false,
      SpecialSkillsId: [],
      isOtherSelected: false, // Tracks if "Other" is selected
      OtherSpecialSkill: "",
      grantPermission: false,
      accept: false,
    },

    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (data) => {
      SharedService.ShowLoader();

      AuthService.register(data).then((res) => {
        SharedService.HideLoader();

        if (res.data.errorCode === 0) {
          SharedService.ShowToast(
            t("Account created successfully, please login now"),
            MessageTypeConstant.Success
          );
          localStorage.setItem("Auth", res.data.data.token);
          localStorage.setItem("userRole", res.data.data.userRole);
          localStorage.setItem("name", res.data.data.name);
          localStorage.setItem("profileImage", res.data.data.profileImage);
          window.location.reload();
        }
        
      });
    },
  });
  return (
    <>
      <div className="row mx-0">
        <div className="d-none d-md-block col-6 register-image">
          <div className="image-overlay">
            <span className="p-5"> {t("WELCOME TO THE TEAM")}</span>{" "}
          </div>
        </div>
        <div className="col-12  col-md-6 home-container">
          <form
            onSubmit={formik.handleSubmit}
            className="p-fluid mb-2 p-1 p-md-5"
          >
            <div className="row">
              <div className="col-12 title  mt-5">{t("Register")}</div>
              <div className="col-12 grey  font-16 mt-3">
                {t("Create new account and be a part in our amazing team!")}
              </div>

              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="FullName"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Full Name")}
                  </label>
                  <InputText
                    id="FullName"
                    name="FullName"
                    className="w-100"
                    value={formik.values.FullName}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.FullName && formik.errors.FullName && (
                    <label className="errorMessage w-100">
                      {formik.errors.FullName}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="UserName"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Email")}
                  </label>
                  <InputText
                  keyfilter={/[^\s]/}
                  id="UserName"
                    name="UserName"
                    className="w-100"
                    value={formik.values.UserName}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.UserName && formik.errors.UserName && (
                    <label className="errorMessage w-100">
                      {formik.errors.UserName}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="password"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Password")}
                  </label>
                  <Password
                    feedback={false}
                    toggleMask
                    id="Password"
                    name="Password"
                    className="block w-100 text-center"
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.Password && formik.errors.Password && (
                    <label className="errorMessage w-100">
                      {formik.errors.Password}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="confirmPassword"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Confirm Password")}
                  </label>
                  <Password
                    feedback={false}
                    toggleMask
                    id="confirmPassword"
                    name="confirmPassword"
                    className="block w-100 text-center"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <label className="errorMessage w-100">
                        {formik.errors.confirmPassword}
                      </label>
                    )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="UserGenderEnum"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Gender")}
                  </label>
                  <Dropdown
                    optionLabel="name"
                    id="UserGenderEnum"
                    value={
                      genders[
                        genders.findIndex(
                          (obj) => obj.code === formik.values.UserGenderEnum
                        )
                      ]
                    }
                    options={genders}
                    onChange={(e) => {
                      formik.setFieldValue("UserGenderEnum", e.value.code);
                    }}
                    className={`w-100`}
                    placeholder={t("select")}
                  />
                  {formik.touched.UserGenderEnum &&
                    formik.errors.UserGenderEnum && (
                      <label className="errorMessage w-100">
                        {formik.errors.UserGenderEnum}
                      </label>
                    )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="DateOfBirth"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Date Of Birth")}
                  </label>
                  <Calendar
                    showIcon
                    id="DateOfBirth"
                    className={`block w-100`}
                    name="DateOfBirth"
                    value={
                      formik.values.DateOfBirth
                        ? new Date(formik.values.DateOfBirth)
                        : ""
                    }
                    maxDate={new Date()}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "DateOfBirth",
                        moment(new Date(e.value)).format("YYYY-MM-DD")
                      );
                    }}
                  />
                  {formik.touched.DateOfBirth && formik.errors.DateOfBirth && (
                    <label className="errorMessage w-100">
                      {formik.errors.DateOfBirth}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="NationalityId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Nationality")}
                  </label>
                  <Dropdown
                    optionLabel="name"
                    id="NationalityId"
                    filter
                    options={nationalities}
                    value={
                      nationalities[
                        nationalities.findIndex(
                          (obj) => obj.code === formik.values.NationalityId
                        )
                      ]
                    }
                    onChange={(e) => {
                      formik.setFieldValue("NationalityId", e.value.code);
                    }}
                    className={`w-100`}
                    placeholder={t("select")}
                  />
                  {formik.touched.NationalityId &&
                    formik.errors.NationalityId && (
                      <label className="errorMessage w-100">
                        {formik.errors.NationalityId}
                      </label>
                    )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="IdNumber"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("ID Number")}
                  </label>
                  <InputText
                    id="IdNumber"
                    name="IdNumber"
                    className="w-100"
                    value={formik.values.IdNumber}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.IdNumber && formik.errors.IdNumber && (
                    <label className="errorMessage w-100">
                      {formik.errors.IdNumber}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="CountryId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Country of Residence")}
                  </label>
                  <Dropdown
                    optionLabel="name"
                    id="CountryId"
                    options={countries}
                    value={
                      countries[
                        countries.findIndex(
                          (obj) => obj.code === formik.values.CountryId
                        )
                      ]
                    }
                    onChange={(e) => {
                      debugger;
                      formik.setFieldValue("CountryId", e.value.code);
                      fillCitiesByCountry(e.value.code);
                    }}
                    filter 
                    className={`w-100`}
                    placeholder={t("select")}
                  />
                  {formik.touched.CountryId && formik.errors.CountryId && (
                    <label className="errorMessage w-100">
                      {formik.errors.CountryId}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="CityId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("City of Residence")}
                  </label>
                  <Dropdown
                    optionLabel="name"
                    id="CityId"
                    options={cities}
                    filter
                    value={
                      cities[
                        cities.findIndex(
                          (obj) => obj.code === formik.values.CityId
                        )
                      ]
                    }
                    onChange={(e) => {
                      formik.setFieldValue("CityId", e.value.code);
                      fillAreasByCity(e.value.code);

                    }}
                    className={`w-100`}
                    placeholder={t("select")}
                  />
                  {formik.touched.CityId && formik.errors.CityId && (
                    <label className="errorMessage w-100">
                      {formik.errors.CityId}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="AreaId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("District/Area of Residence")}
                  </label>
                  <Dropdown
                    id="AreaId"
                    filter
                    value={formik.values.AreaId}
                    // value={
                    //   districts[
                    //     districts.findIndex(
                    //       (obj) => obj.code === formik.values.AreaId
                    //     )
                    //   ]
                    // }
                    onChange={(e) => {
                      debugger;
                       formik.setFieldValue("AreaId", e.value);
                    }}
                    options={districts}
                    optionGroupLabel="label" 
                    optionLabel="label"
                    optionGroupChildren="items" 
                    // optionGroupTemplate={groupedItemTemplate}
                    className={`w-full md:w-14rem`}
                    placeholder={t("select")}
                  />
                  {formik.touched.AreaId && formik.errors.AreaId && (
                    <label className="errorMessage w-100">
                      {formik.errors.AreaId}
                    </label>
                  )}
                </span>
              </div>

              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="PhoneNumber"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Phone Number")}
                  </label>
                  <InputText
                    id="PhoneNumber"
                    name="PhoneNumber"
                    className="w-100"
                    value={formik.values.PhoneNumber}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.PhoneNumber && formik.errors.PhoneNumber && (
                    <label className="errorMessage w-100">
                      {formik.errors.PhoneNumber}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="ConfirmMobileNo"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Confirm Phone Number")}
                  </label>
                  <InputText
                    id="ConfirmMobileNo"
                    name="ConfirmMobileNo"
                    className="w-100"
                    value={formik.values.ConfirmMobileNo}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.ConfirmMobileNo &&
                    formik.errors.ConfirmMobileNo && (
                      <label className="errorMessage w-100">
                        {formik.errors.ConfirmMobileNo}
                      </label>
                    )}
                </span>
              </div>
              <div className="col-12 mt-1  ">
                <label
                  htmlFor="PersonnalPhotoFile"
                  className="d-inline-block mt-4  w-100"
                >
                  {t("Upload Personal Photo")}
                </label>
                <span className="custom-file-upload">
                  <input
                    type="file"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "PersonnalPhotoFile",
                        e.currentTarget.files[0]
                      );
                    }}
                  />
                  {formik.touched.PersonnalPhotoFile &&
                    formik.errors.PersonnalPhotoFile && (
                      <label className="errorMessage w-100">
                        {formik.errors.PersonnalPhotoFile}
                      </label>
                    )}
                </span>
              </div>
              <div className="col-12 mt-1  ">
                <label
                  htmlFor="IdPhotoFile"
                  className="d-inline-block mt-4  w-100"
                >
                  {t("Upload ID/Passport for Non-Jordanians")}
                </label>
                <span className="text-start">
                  <input
                    type="file"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "IdPhotoFile",
                        e.currentTarget.files[0]
                      );
                    }}
                  />
                  {formik.touched.IdPhotoFile && formik.errors.IdPhotoFile && (
                    <label className="errorMessage w-100">
                      {formik.errors.IdPhotoFile}
                    </label>
                  )}
                </span>
              </div>

              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="EducationStatusId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Educational Status")}
                  </label>
                  <Dropdown
                    optionLabel="name"
                    id="EducationStatusId"
                    options={educationStatus}
                    value={
                      educationStatus[
                        educationStatus.findIndex(
                          (obj) => obj.code === formik.values.EducationStatusId
                        )
                      ]
                    }
                    onChange={(e) => {
                      debugger;
                      formik.setFieldValue("EducationStatusId", e.value.code);
                    }}
                    className={`w-100`}
                    placeholder={t("select")}
                  />
                  {formik.touched.EducationStatusId &&
                    formik.errors.EducationStatusId && (
                      <label className="errorMessage w-100">
                        {formik.errors.EducationStatusId}
                      </label>
                    )}
                </span>
              </div>


              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="FieldOfStudyId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Field Of Study")}
                  </label>
                  <Dropdown
                    optionLabel="name"
                    id="FieldOfStudyId"
                    value={
                      fieldofStudies[
                        fieldofStudies.findIndex(
                          (obj) => obj.code === formik.values.FieldOfStudyId
                        )
                      ]
                    }
                    onChange={handleDropdownChange}
                    options={fieldofStudies}
                    className={`w-100`}
                    placeholder={t("select")}
                  />
                  {formik.touched.FieldOfStudyId && formik.errors.FieldOfStudyId && (
                    <label className="errorMessage w-100">
                      {formik.errors.FieldOfStudyId}
                    </label>
                  )}

{isOtherSelected && (
          <>
            <InputText
              className="w-100 mt-2"
              placeholder={t("الرجاء تحديد مجال الدراسه")}
              value={formik.values.OtherFieldOfStudy || ""}
              onChange={(e) => formik.setFieldValue("OtherFieldOfStudy", e.target.value)}
            />
            {formik.touched.OtherFieldOfStudy && formik.errors.OtherFieldOfStudy && (
              <label className="errorMessage w-100">
                {formik.errors.OtherFieldOfStudy}
              </label>
            )}
          </>
        )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="BloodTypeId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Blood Type")}
                  </label>
                  <Dropdown
                    optionLabel="name"
                    id="BloodTypeId"
                    value={
                      bloodTypes[
                        bloodTypes.findIndex(
                          (obj) => obj.code === formik.values.BloodTypeId
                        )
                      ]
                    }
                    onChange={(e) => {
                      debugger;
                      formik.setFieldValue("BloodTypeId", e.value.code);
                    }}
                    options={bloodTypes}
                    className={`w-100`}
                    placeholder={t("select")}
                  />
                  {formik.touched.BloodTypeId && formik.errors.BloodTypeId && (
                    <label className="errorMessage w-100">
                      {formik.errors.BloodTypeId}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="Experience"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Volunteering Experience")}
                  </label>
                  <InputTextarea
                    id="Experience"
                    name="Experience"
                    rows={5}
                    cols={10}
                    value={formik.values.Experience}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.Experience && formik.errors.Experience && (
                    <label className="errorMessage w-100">
                      {formik.errors.Experience}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-12  mt-2 ">
                <label
                  htmlFor="IsCurrentyWorking"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Are you currently working?")}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCurrentyWorkingYes"}
                      name="IsCurrentyWorkingYes"
                      value={formik.values.IsCurrentyWorking}
                      onChange={(e) => {
                        formik.setFieldValue("IsCurrentyWorking", e.checked);
                      }}
                      checked={formik.values.IsCurrentyWorking}
                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCurrentyWorkingNo"}
                      name="IsCurrentyWorkingNo"
                      value={!formik.values.IsCurrentyWorking}
                      onChange={(e) => {
                        formik.setFieldValue("IsCurrentyWorking", !e.checked);
                      }}
                      checked={!formik.values.IsCurrentyWorking}
                    />
                    <label className="mx-2 ">{t("No")}</label>
                  </div>
                </div>
              </div>
              <div className="col-12  mt-2 ">
                <label
                  htmlFor="IsCurrentlyStudent"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Are you currently student?")}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCurrentlyStudent"}
                      name="IsCurrentlyStudent"
                      value={formik.values.IsCurrentlyStudent}
                      onChange={(e) => {
                        formik.setFieldValue("IsCurrentlyStudent", e.checked);
                      }}
                      checked={formik.values.IsCurrentlyStudent}
                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCurrentlyStudentNo"}
                      name="IsCurrentlyStudentNo"
                      value={!formik.values.IsCurrentlyStudent}
                      onChange={(e) => {
                        formik.setFieldValue("IsCurrentlyStudent", !e.checked);
                      }}
                      checked={!formik.values.IsCurrentlyStudent}
                    />
                    <label className="mx-2 ">{t("No")}</label>
                  </div>
                </div>
              </div>

              <div className="col-12  mt-3 ">
                <label
                  htmlFor="IsWillGovernarates"
                  className="d-inline-block mt-2  w-100"
                >
                  {t(
                    "Are you willing to volunteer in all Jordanian Governarates?"
                  )}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsWillGovernarates"}
                      name="IsWillGovernarates"
                      value={formik.values.IsWillGovernarates}
                      onChange={(e) => {
                        formik.setFieldValue("IsWillGovernarates", e.checked);
                      }}
                      checked={formik.values.IsWillGovernarates}
                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsWillGovernaratesNo"}
                      name="IsWillGovernaratesNo"
                      value={!formik.values.IsWillGovernarates}
                      onChange={(e) => {
                        formik.setFieldValue("IsWillGovernarates", !e.checked);
                      }}
                      checked={!formik.values.IsWillGovernarates}
                    />
                    <label className="mx-2 ">{t("No")}</label>
                  </div>
                </div>
              </div>

              <div className="col-12  mt-3 ">
                <label
                  htmlFor="IsWillWeekends"
                  className="d-inline-block mt-2  w-100"
                >
                  {t(
                    "Are you willing to volunteer on weekends,holidays and special occasions?"
                  )}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsWillWeekends"}
                      name="IsWillWeekends"
                      value={formik.values.IsWillWeekends}
                      onChange={(e) => {
                        formik.setFieldValue("IsWillWeekends", e.checked);
                      }}
                      checked={formik.values.IsWillWeekends}
                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>

                  <div className="col-3">
                    <Checkbox
                      inputId={"IsWillWeekendsNo"}
                      name="IsWillWeekendsNo"
                      value={!formik.values.IsWillWeekends}
                      onChange={(e) => {
                        formik.setFieldValue("IsWillWeekends", !e.checked);
                      }}
                      checked={!formik.values.IsWillWeekends}
                    />
                    <label className="mx-2 ">{t("No")}</label>
                  </div>
                </div>
              </div>

              
              <div className="col-12  mt-3 ">
                <label
                  htmlFor="IsCancerSurvivor"
                  className="d-inline-block mt-2  w-100"
                >
                  {t(
                    "Are you a cancer survivor?"
                  )}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCancerSurvivor"}
                      name="IsCancerSurvivor"
                      value={formik.values.IsCancerSurvivor}
                      onChange={(e) => {
                        formik.setFieldValue("IsCancerSurvivor", e.checked);
                      }}
                      checked={formik.values.IsCancerSurvivor}
                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>

                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCancerSurvivorNo"}
                      name="IsCancerSurvivorNo"
                      value={!formik.values.IsCancerSurvivor}
                      onChange={(e) => {
                        formik.setFieldValue("IsCancerSurvivor", !e.checked);
                      }}
                      checked={!formik.values.IsCancerSurvivor}
                    />
                    <label className="mx-2 ">{t("No")}</label>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-1 text-center mt-2">
                <span className="text-start">
                  <label
                    htmlFor="SpecialSkillsId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Special Skills")}
                  </label>
                  <MultiSelect 
                    optionLabel="name"
                    id="SpecialSkillsId"
                    value={formik.values.SpecialSkillsId.map((id) =>
                      specialSkills.find((obj) => obj.code === id)
                    )}
                    onChange={(e) => {
                      debugger;
                      const selectedValues = Array.isArray(e.value)
                      ? e.value.map((option) => option.code)
                      : e.value
                      ? [e.value.code]
                      : []; // Ensure it's always an array
                      formik.setFieldValue("SpecialSkillsId", selectedValues);
              
                      const selectedText  = Array.isArray(e.value)
                      ? e.value.map((option) => option.name)
                      : e.value
                      ? [e.value.name]
                      : []; // Ensure it's always an array

                      // Check if "Other" is selected
                      const isOtherSelected = selectedText.includes("Other")||selectedText.includes("أخرى");
                      formik.setFieldValue("isOtherSelected", isOtherSelected);

                      if (!isOtherSelected)       
                          formik.setFieldValue("OtherSpecialSkill", "");

                    }}
                    options={specialSkills}
                    className={`w-100`}
                    placeholder={t("select")}
                    multiple  

                  />
                  {formik.touched.SpecialSkillsId &&
                    formik.errors.SpecialSkillsId && (
                      <label className="errorMessage w-100">
                        {formik.errors.SpecialSkillsId}
                      </label>
                    )}

{formik.values.isOtherSelected && (
      <input
        type="text"
        className="form-control mt-2"
        placeholder={t("Specify other special skills")}
        value={formik.values.OtherSpecialSkill || ""}
        onChange={(e) => formik.setFieldValue("OtherSpecialSkill", e.target.value)}
      />
    )}
    {formik.touched.OtherSpecialSkill && formik.errors.OtherSpecialSkill && (
      <label className="errorMessage w-100">{formik.errors.OtherSpecialSkill}</label>
    )}
                </span>
              </div>

              <div className="col-12  mt-4 ">
                <Checkbox
                  inputId={"grantPermission"}
                  name="grantPermission"
                  value={formik.values.grantPermission}
                  onChange={(e) => {
                    formik.setFieldValue("grantPermission", e.checked);
                  }}
                  checked={formik.values.grantPermission}
                />
                <label
                  htmlFor={"grantPermission"}
                  className="mx-2 p-checkbox-label d-inline"
                  style={{ color: "#263871" }}
                >
                  {t("I agree to grant permission for the use of the photograph(s) or electronic media images in any presentation of any and all kind whatsoever.")}
                </label>
                {formik.touched.grantPermission &&
                  formik.errors.grantPermission && (
                    <label className="errorMessage w-100">
                      {formik.errors.grantPermission}
                    </label>
                  )}
              </div>

              <div className="col-12  mt-4 ">
                <Checkbox
                  inputId={"accept"}
                  name="accept"
                  value={formik.values.accept}
                  onChange={(e) => {
                    formik.setFieldValue("accept", e.checked);
                  }}
                  checked={formik.values.accept}
                />
                <label htmlFor={"accept"} className="mx-2 ">
                  {t("I agree to the Terms of services and Privacy policy")}
                </label>
                {formik.touched.accept && formik.errors.accept && (
                  <label className="errorMessage w-100">
                    {formik.errors.accept}
                  </label>
                )}
              </div>

              <div className="col-12 mt-4 text-center">
                <Button
                  label={t("Create Account")}
                  className="btn btn-primary  w-100"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
